@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --input-bg-color: #e2e2e2;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: "KuMincho", "EB Garamond", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: XiaoZhang;
  src: url("/public/XiaoZhangQingKuangBenPaoSaYe-2.ttf") format("opentype");
}

@font-face {
  font-family: KuMincho;
  src: url("/public/KuMincho-R.otf") format("opentype");
}

@font-face {
  font-family: KuMincho;
  font-weight: bold;
  src: url("/public/KuMincho-R.otf") format("opentype");
}

button:focus,
.input:focus {
  outline: none;
}

.input {
  display: block;
  margin-bottom: 1rem;
  padding: 0.25rem;
  background-color: var(--input-bg-color);
  background-image: url("/public/img/grain.png");
  border: 2px solid #999;
  border-radius: 0.25rem;
}

.input:focus {
  border-color: #777;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

.selected-seat {
  filter: invert(14%) sepia(47%) saturate(3766%) hue-rotate(9deg)
    brightness(102%) contrast(103%);
}

.reserved-seat {
  filter: invert(49%) sepia(9%) saturate(21%) hue-rotate(337deg) brightness(97%)
    contrast(94%);
}

.img_full img {
  width: 100%;
}

.text-cave {
  font-family: XiaoZhang;
  font-size: 25vh;
  line-height: 20vh;
}

.html_parser p {
  font-family: KuMincho !important;
}

.html_parser img {
  margin-top: 2rem;
  border-radius: 4px;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
